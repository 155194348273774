define('ember-intl/helpers/intl-get', ['exports', 'ember', 'ember-intl/helpers/l'], function (exports, _ember, _emberIntlHelpersL) {
  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  'use strict';

  var Helper = _ember['default'].Helper;
  var inject = _ember['default'].inject;
  var get = _ember['default'].get;
  var deprecate = _ember['default'].deprecate;

  var IntlGetHelper = Helper.extend({
    intl: inject.service(),

    init: function init() {
      this._super.apply(this, arguments);

      deprecate('[ember-int] intl-get is deprecated, use {{t \'translation.key\'}} or {{format-message \'translation.key\'}}', false, {
        id: 'ember-intl-t-helper'
      });

      get(this, 'intl').on('localeChanged', this, this.recompute);
    },

    compute: function compute(params) {
      var hash = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      return new _emberIntlHelpersL.LiteralWrapper(get(this, 'intl').lookup(params[0], hash.locale));
    },

    destroy: function destroy() {
      this._super.apply(this, arguments);

      get(this, 'intl').off('localeChanged', this, this.recompute);
    }
  });

  exports['default'] = IntlGetHelper;
});