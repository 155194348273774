define('ember-cli-font-awesome/components/fa-icon', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'i',

    classNames: ['fa'],

    classNameBindings: ['iconCssClass', 'flipCssClass', 'rotateCssClass', 'sizeCssClass', 'pullCssClass', 'stackCssClass', 'spin:fa-spin', 'fixedWidth:fa-fw', 'listItem:fa-li', 'border:fa-border', 'pulse:fa-pulse', 'inverse:fa-inverse'],

    attributeBindings: ['ariaHiddenAttribute:aria-hidden', 'title'],

    iconCssClass: computed('icon', function () {
      var icon = this.get('icon');
      if (icon) {
        return icon.match(/^fa-/) ? icon : 'fa-' + icon;
      }
    }),

    flipCssClass: computed('flip', function () {
      var flip = this.get('flip');
      if (flip) {
        return flip.match(/^fa-flip/) ? flip : 'fa-flip-' + flip;
      }
    }),

    rotateCssClass: computed('rotate', function () {
      var rotate = this.get('rotate');
      if (rotate) {
        return rotate.match(/^fa-rotate/) ? rotate : 'fa-rotate-' + rotate;
      }
    }),

    sizeCssClass: computed('size', function () {
      var size = this.get('size');
      if (size) {
        if (size.match(/^fa-/)) {
          return size;
        } else {
          return size.match(/(?:lg|x)$/) ? 'fa-' + size : 'fa-' + size + 'x';
        }
      }
    }),

    pullCssClass: computed('pull', function () {
      var pull = this.get('pull');
      if (pull) {
        return 'fa-pull-' + pull;
      }
    }),

    stackCssClass: computed('stack', function () {
      var stack = this.get('stack');
      if (stack) {
        if (stack.match(/^fa-/)) {
          return stack;
        } else {
          return stack.match(/x$/) ? 'fa-stack-' + stack : 'fa-stack-' + stack + 'x';
        }
      }
    }),

    ariaHiddenAttribute: computed('ariaHidden', function () {
      var ariaHidden = this.get('ariaHidden');
      return ariaHidden !== false ? true : undefined;
    })
  });
});