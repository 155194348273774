define('emberx-file-input/test-helpers/select-file-unit', ['exports'], function (exports) {
  'use strict';

  function buildChangeEvent() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var event = document.createEvent('Events');
    event.initEvent('change', true, true);
    $.extend(event, options);
    return event;
  }

  exports['default'] = function (selector, file) {
    var changeEvent = buildChangeEvent({ testingFiles: [file] });
    $(selector)[0].dispatchEvent(changeEvent);
  };
});